import React from 'react';

const NoPage = () => {
  return (
    <div class="text-center mt-5">
    <div className="container">
      <h1>404</h1>
      <p>Page not found</p>
    </div>
    </div>
  )
}

export default NoPage;